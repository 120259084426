<template>
  <div class="w-100 fill-height">
    <v-container>
      <!-- Alerts -->
      <v-alert
        v-if="success"
        v-text="success"
        type="success"
        dense
        dismissible
        class="mb-3"
      />

      <v-alert
        v-if="error"
        v-text="error"
        type="error"
        dense
        dismissible
        class="mb-3"
      />

      <v-alert
        v-if="isRevision"
        type="warning"
        color="warning darken-1"
        dense
        class="mb-3"
      >
        You are currently editing an existing incident.
        <br />
        Any previously performed assessments could become invalid and should be re-evaluated manually.
      </v-alert>

      <!-- Form -->
      <v-form ref="form">
        <v-card
          :loading="loading"
          outlined
          flat
        >
          <v-toolbar dense flat>
            <v-toolbar-title v-text="title" />
          </v-toolbar>

          <v-divider />

          <v-card-text>
            <p class="red--text text--darken-1">* required input</p>

            <!-- Dynamic form -->
            <component v-if="component" v-bind:is="component" ref="dynamic_form" />

            <!-- No dynamic form available -->
            <v-alert
              v-if="!component"
              type="info"
              outlined
              text
              dense
              class="mt-5 mb-0"
              v-text="'No available options'"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <pibot-pw-confirm
              elevation="0"
              color="success darken-1"
              :loading="loading"
              :disabled="!component"
              @click="validate()"
              @confirmed="onSubmit()"
            >
              <template #default>Submit</template>
              <template #title>Authenticate</template>
              <template #text v-if="isRevision">
                <span class="error--text text--darken-1">
                  Are you sure you want to update this incident? Any previously performed assessments <strong>could become invalid</strong> and should be re-evaluated manually.
                </span>
              </template>
            </pibot-pw-confirm>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import eprs from '@/apps/eprs/utils/mixins/eprs.mixin'

export default {
  name: 'pibot-eprs-incident-form',
  mixins: [eprs],
  components: {
    'pibot-eprs-incident': () => import('../form-components/Incident'),
    'pibot-pw-confirm': () => import('@/components/buttons/PasswordConfirm')
  },
  computed: {
    title () {
      let title = 'Incident'

      if (this.isNew) title = 'New Incident'

      return title
    },
    component () {
      return 'pibot-eprs-incident'
    }
  },
  mounted () {
    this.unsetMessages()
  },
  methods: {
    ...mapActions({
      create: 'eprs/socket_createIncident',
      update: 'eprs/socket_updateIncident'
    }),
    validate () {
      const isValid = this.$refs.dynamic_form.validate()

      // Easy fix for 'preventing' dialog if form is invalid
      if (!isValid) setTimeout(() => { this.$refs.confirm.dialog = false }, 10)

      return isValid
    },
    async onSubmit () {
      if (!this.validate()) return

      const payload = {
        ...this.$refs.dynamic_form.form,
        incident_id: this.iid,
        escalated: true
      }
      const promise = this.isNew ? this.create : this.isRevision ? this.update : undefined
      if (!promise) return

      promise(payload)
        .then(() => {
          this.$refs.dynamic_form.reset()
          this.$router.push('/eprs/')
        })
    }
  }
}
</script>
